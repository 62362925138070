import React from 'react';
import {Link} from 'react-router-dom';
import Roster from '../assets/docs/Roster.pdf';
import Calendar from '../assets/docs/Calendar.pdf';
import Winners from '../assets/docs/Winners.pdf';
import Tournament from '../assets/docs/regulations/NTBARegs23.pdf';
import System from '../assets/docs/regulations/ABFSystemRegs2017.pdf';
import Alerts from '../assets/docs/regulations/ABFAlertRegs2017.pdf';
import Butler from '../assets/docs/regulations/NTButlerPairsChampionship2023-SuppRegs.pdf';
import GNOT from '../assets/docs/regulations/GNOTSuppRegs2024.pdf';
import Bid from '../assets/docs/regulations/ABFWBBBRegs2018.pdf';
import Constitution from '../assets/docs/Constitution2024.pdf';
import Flyer from '../assets/docs/TGBF2024.pdf';
function Navigation(){
  return (
    <div className="menu" id="navbar">
      
        <ul>

          <li><Link to="/">Home</Link></li>

          <li><Link to="/where">Where to play</Link></li>

          <li><Link to ="/how">How to Learn</Link></li>

          <li><Link to={Flyer} target="_blank">Territory Gold Bridge Festival</Link>

            <ul>
              <li><Link to={Flyer} target="_blank">Latest Flyer</Link></li>
                  <li><Link to ="/tgbf2022">2022 TGBF Results</Link></li>
                  <li><Link to ="/tgbf2023">2023 TGBF Results</Link></li>
            </ul></li>

          <li><Link to ="/">About the NTBA</Link>
                  
            <ul>
              <li><Link to ="/role">Role</Link></li>
              <li><Link to ="/committee">Committee</Link></li>
              <li><Link to ="/tc">Tournament Committee</Link></li>
              <li><Link to={Constitution} target="_blank">Constitution</Link></li>
            </ul>
          </li>
          

          <li><Link to ="/notices">Notices</Link></li>

          <li><Link to="/">NTBA Events</Link>
            
          <ul>
              <li><Link to={Roster} target="_blank">Roster</Link></li>

              <li><Link to={Calendar} target="_blank">Events Calendar</Link></li>

              <li><Link to={Winners} target="_blank">Annual Prize Winners</Link></li>

            </ul></li>

          <li><Link to="/">NTBA Regulations</Link>

            <ul>
              <li><Link to={Tournament} target="_blank">Tournament Regulations</Link></li>
              <li><Link to={System} target="_blank">System Regulations</Link></li>
              <li><Link to={Alerts} target="_blank">Alert Regulations</Link></li>
              <li><Link to={Butler} target="_blank">Butler Pairs Regulations</Link></li>
              <li><Link to={GNOT} target="_blank">GNOT Supplementary Regulations</Link></li>
              <li><Link to={Bid} target="_blank">Written Bidding and Bidding Boxes</Link></li>

            </ul></li>

          <li><Link to="/">External Links</Link>

            <ul>

              <li><a href="http://www.abf.com.au" target="ABF">Australian Bridge Federation</a></li>

              <li><a href="http://abfmasterpoints.com.au/myawards.asp" target="MPcentre">Your Masterpoints</a></li>

            </ul></li>
      </ul>

    </div>
)

}

export default Navigation;
